<template>
    <div class="container">
        <headeBar title="团课会员卡" left-arrow @click-left="appBack"/>
        <div class="content">
            <!-- 用户 -->
            <div class="row-between-center">
                <div class="user row-start-center">
                    <img :src="userInfo.headImg" class="avator flex-none" alt=""/>
                    <div class="name">
                        <p class="f28 fw6 ellipsis">{{ userInfo.nickName }}</p>
                    </div>
                </div>
                <div class="row-start-center">
                    <van-popover v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom-end" @select="moreActions" id="abc">
                        <template #reference>
                            <div class="row-center-center more-btn">更多</div>
                        </template>
                    </van-popover>
<!--                    <div v-if="userEquityCardVO.equityCardCouponAfterPriceVO && userEquityCardVO.equityCardCouponAfterPriceVO.couponDeductions" class="btn f24 fw6 col-center-center" @click="goBuy">-->
<!--                        <div class="row-center-center">续费</div>-->
<!--                        <p class="ellipsis">限时立减{{ userEquityCardVO.equityCardCouponAfterPriceVO.couponDeductions }}元</p>-->
<!--                    </div>-->
                    <div class="btn btn1 f24 fw6 col-center-center" @click="goBuy">
                        <div class="row-center-center">续费</div>
                    </div>
                </div>
            </div>
            <!-- 卡面 -->
            <div class="card"
                 :style="`background-image: url(${['ACTIVE', 'WAIT_OPEN'].includes(userEquityCardVO.status) ? userEquityCardVO.backgroundImgUrl : userEquityCardVO.failureImgUrl})`">
                <div class="row-between-start">
                    <p class="title f56" style=" font-family: PuHuiTi;">{{ userEquityCardVO.equityCardName }}</p>
                    <p class="status status1 f24 fw6" v-html="status[userEquityCardVO.status].html"></p>
                </div>
                <div class="limit f20 row-between-end">
                    <div v-if="userEquityCardVO.status === 'ACTIVE'"><p>有效期共剩余{{userEquityCardVO.validityRemainingDays }}天{{ userEquityCardVO.isAutoRenew === 1 ? '（自动续费）' : '' }}</p></div>
                    <div v-else-if="userEquityCardVO.status === 'SUSPEND'" class="col-start-start">
                        <p style="margin-bottom: 2.13vw">冻结日期：{{ userEquityCardVO.suspendStartDate }}-{{userEquityCardVO.suspendEndDate }}（共{{differenceDate(userEquityCardVO.suspendStartDate, userEquityCardVO.suspendEndDate) }}天）</p>
                        <p>有效期剩余{{ userEquityCardVO.validityRemainingDays }}天</p>
                    </div>
                    <div v-else-if="userEquityCardVO.status === 'WAIT_ACTIVE'"><p>按购买时间依次生效后计算有效期</p></div>
                    <div v-else-if="userEquityCardVO.status === 'WAIT_OPEN'"><p>有效期剩余{{ userEquityCardVO.validityRemainingDays }}天</p></div>
                    <div v-else></div>
                    <div><p @click="goSellRecord(0)" class="row-center-center">共{{ userEquityCardVO.availableNum || 0}}张可使用 <van-icon name="arrow"/></p></div>
                </div>
            </div>
            <template  v-if="!['RECEIVED', 'EXPIRED'].includes(userEquityCardVO.status)">
                <div class="item-box-title">最新可使用卡</div>
                <div class="item-box">
                    <div class="item row-between-start">
                        <img :src="userEquityCardVO.iconUrl" alt="">
                        <div>
                            <div class="name row-between-center">{{ userEquityCardVO.equityCardName }}</div>
                            <div class="date1">来源：{{userEquityCardVO.equityCardSources}}</div>
                            <div v-if="userEquityCardVO.status === 'WAIT_ACTIVE'" class="date">生效期：按购买时间依次生效后计算有效期</div>
                            <div v-else-if="userEquityCardVO.status === 'WAIT_RECEIVE'" class="date">生效期：依次生效后计算有效期</div>
                            <div v-else-if="userEquityCardVO.status === 'WAIT_OPEN'" class="date">
                                <template v-if="userEquityCardVO.openDeadlineDate">生效期：下次进馆自动生效，在{{ userEquityCardVO.openDeadlineDate }}前未进馆则自动{{ (userEquityCardVO.isGivingCard == 0 && userEquityCardVO.source === 'FRIEND_GIVING')? '生效': '退款'}}</template>
                                <template v-else>生效期：下次进馆自动生效，永久有效</template>
                            </div>
                            <div v-else-if="userEquityCardVO.status === 'AUTO_REFUND'" class="dates">生效期：未生效，未进馆自助消费自动退款</div>
                            <div v-else-if="userEquityCardVO.validityStartDate && userEquityCardVO.validityEndDate" class="date">生效期：{{userEquityCardVO.validityStartDate}} - {{ userEquityCardVO.validityEndDate }}（已用{{ userEquityCardVO.hasActiveDays || 0}}天）</div>
                            <div v-else class="date">生效期：未生效</div>
                        </div>
                    </div>
                    <div v-if="userEquityCardVO.isCanGiving || userEquityCardVO.isCanActive" class="item-box-btn row-end-center">
                        <p v-if="userEquityCardVO.isCanGiving && userEquityCardVO.type === 0" @click="share(userEquityCardVO.userEquityCardId)" class="row-center-center">赠送好友</p>
                        <p v-if="userEquityCardVO.isCanActive" @click="showActivate = true" class="row-center-center active">激活使用</p>
                    </div>
                </div>
                <!-- 权益日期范围 -->
                <div class="row-between-center">
                    <p class="equity-date f22">当前权益<span>（每30天更新权益）</span></p>
                </div>
            </template>
            <div v-if="['ACTIVE', 'SUSPEND', 'WAIT_OPEN', 'WAIT_ACTIVE'].includes(userEquityCardVO.status)">
                <!-- 高峰段 -->
                <div class="cl-card" v-for="item in limitEquityList" :key="item.equityName">
                    <div class="row-between-start">
                        <p class="title f28 fw6">{{ item.equityName }}</p>
                    </div>
                    <div class="p1 f24">{{ item.equityInstructions }}</div>


                    <div class="coupon-list">
                        <div v-for="(vo, index) in item.vouchers" :key="index" class="coupon-item-box">
                            <div v-if="vo.type === '1'" class="coupon-item" :style="!(index%4)? 'margin-left: 0': ''" :class="{couponItemGray: (vo.status !== 0)}">
                                <h2><span>¥</span>{{ vo.price }}</h2>
                                <p>{{ vo.statusDescribe }}</p>
                            </div>

                            <div v-else class="big-coupon-item" :style="!(index%2)? 'margin-left: 0': ''" :class="{couponItemGray: (vo.status !== 0)}">
                                <div>
                                    <h2><span>¥</span>{{ vo.price }}</h2>
                                    <p>{{ vo.type | typeName }}</p>
                                </div>
                                <h4>{{ vo.statusDescribe }}</h4>
                            </div>
                        </div>
                    </div>
                    <p @click="goMyVoucher" v-if="userEquityCardVO.status !== 'WAIT_OPEN'" style="margin-top: 2.13vw;color: #FF6E00" class="status f24 row-start-center">已发放至优惠券账户<van-icon size="10" color="#FF6E00" name="arrow"/></p>
                    <p v-else-if="userEquityCardVO.status === 'WAIT_ACTIVE'" style="margin-top: 2.13vw;color: #267DFF" class="status f24 row-start-center">权益待生效</p>
                </div>
                <!-- 中峰段 赠团课 -->
                <div class="scroll-box">
                    <div class="cl-card small flex-wrap flex-none" v-for="item in userEquityCardEquityVOList"
                         :key="item.equityName">
                        <div class="row-between-center">
                            <p class="title f28 fw6">{{ item.equityName }}</p>
                        </div>
                        <div class="p1 f24" v-html="item.equityInstructions"></div>
                        <p v-if="item.equityType === 1 && userEquityCardVO.status === 'WAIT_ACTIVE'" class="status f24" :style="{ color: status[userEquityCardVO.status].color }">权益待生效</p>
                        <p v-else-if="item.equityType === 1 && userEquityCardVO.status !== 'WAIT_OPEN'" class="status f24" :style="{ color: status[userEquityCardVO.status].color }">已发放至优惠券账户</p>
                        <p v-else-if="item.equityType === 4" class="status f24" style="color: #FF6E00">已发放至卡包</p>
                        <p v-else class="status f24" :style="{ color: status[userEquityCardVO.status].color }">权益<span v-html="status[userEquityCardVO.status].htmlSellRecord"></span></p>
                    </div>
                </div>
            </div>
            <!-- 跳转 -->
            <!-- <div class="cl-card" style="padding: 0;margin-top: 5.33vw">
                <van-cell title="开卡续费记录" is-link @click="goSellRecord(0)"/>
                <van-cell title="自动续费" is-link @click="goSellRecord(1)"/>
                <van-cell title="活动规则" is-link @click="showRule(1)"/>
                <van-cell title="常见问题" is-link @click="showRule(2)"/>
            </div>-->
        </div>
        <!-- 规则 -->
        <van-popup v-model="ruleShow">
            <div class="popup-box col-start-center">
                <div class="content rule-box f28">
                    <div class="scroll">
                        <p class="title f36 fw6">{{ ruleTitle }}</p>
                        {{ rule }}
                    </div>
                </div>
                <van-icon name="close" size="30" color="#fff" @click="ruleShow = false"/>
            </div>
        </van-popup>
        <Activate :show.sync="showActivate" :user-equity-card-id="userEquityCardVO.userEquityCardId" @confirm="getCardDetail" :cardType="1"></Activate>
    </div>
</template>

<script>
import fastclick from 'fastclick'
import userMixin from '@/mixin/userMixin'
import {newAppBack, initBack, goMyVoucher, getAppVersion} from '@/lib/appMethod'
import {getFormatDate, getParam, compareVersion} from '@/lib/utils'
import headeBar from '@/components/app/headBar'
import {status, scheduleActivityRule, FAQ} from './const.js'
import wx from 'weixin-js-sdk'
import Activate from "@/views/month-card/components/activate.vue";

export default {
    mixins: [userMixin],
    components: {
        Activate,
        headeBar,
    },
    data() {
        return {
            limitEquityList: [], // 高峰段的
            userEquityCardEquityVOList: [], // 权益列表 (不包含 高峰段的)
            userEquityCardVO: {
                status: 'ACTIVE',
            }, // 卡详情
            userMembershipCardId: '',
            userInfo: {}, // 用户信息
            status,
            activityRule: scheduleActivityRule,
            FAQ: FAQ[1],
            rule: '',
            ruleTitle: '',
            cardDate: '',
            ruleShow: false,
            showActivate: false,
            showPopover: false,
            actions: [{text: '开卡记录'}, {text: '使用规则'}, {text: '常见问题'}],
        }
    },
    filters: {
        typeName(v) {
            switch (v) {
                case '1':
                    return '团课券'
                case '2':
                    return '自助券'
                case '3':
                    return '通用券'
                case '4':
                    return 'MINI私教券'
                case '8':
                    return '私教券'
                case '9':
                    return '实物券'
                case '16':
                    return '小班课券'
                default:
                    return ''
            }
        }
    },
    computed: {},
    async created() {
        await this.$getAllInfo()
        initBack()

        this.userMembershipCardId = getParam().userMembershipCardId
        this.cardDate = getParam().cardDate
        this.getCardDetail()
        this.initQr()
    },
    mounted() {
        // 解决iOS设备 必须点击两次才触发的问题
        fastclick.attach(document.getElementById('abc'))
    },
    methods: {
        appBack: newAppBack,
        goMyVoucher() {
            goMyVoucher('6')
        },
        getCardDetail() {
            this.$axios.post(`${this.baseURLApp}/user/equityCard/detail`, {
                userId: this.userId,
                token: this.token,
                userEquityCardId: this.userMembershipCardId



            }).then((res) => {


                this.limitEquityList = []
                this.userEquityCardEquityVOList = []
                res.data.userEquityCardEquityVOList.map((item) => {
                    (item.equityType === 1 && item.vouchers !== undefined) ? this.limitEquityList.push(item) : this.userEquityCardEquityVOList.push(item)
                })


              // this.limitEquityList = res.data?.discountCoupons || []
              // this.userEquityCardEquityVOList = res.data?.userEquityCardEquityVOList || []
              this.userEquityCardVO = res.data.userEquityCardVO
            })
        },
        goBuy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership&cityId=3`,
                })
            } else {
                this.$router.push('/superdeer-activity/sell-membership?cityId=3')
            }
        },
        goSellRecord(active) {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/sell-record&userId=1&token=1&active=${active}&userMembershipCardId=${this.userEquityCardVO.equityCardId}`,
                })
            } else {
                this.$router.push(`/month-card/sell-record?active=${active}&userMembershipCardId=${this.userEquityCardVO.equityCardId}`)
            }
        },
        goUseRecord() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/use-record-self&userId=1&token=1&userMembershipCardId=${this.userMembershipCardId}`,
                })
            } else {
                this.$router.push('/month-card/use-record-self?userMembershipCardId=' + this.userMembershipCardId)
            }
        },
        initQr() {
            this.$axios
                .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                    hasWx: true,
                    userId: this.userId,
                    token: this.token,
                    width: 240,
                    height: 240,
                    webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                    scene: JSON.stringify({
                        // inviteId: this.userId,
                        source: '2022AugustPromotion',
                        webUrl: `${window.location.origin}/#/superdeer-activity/promotion-202208/index`,
                    }),
                })
                .then((res) => {
                    this.userInfo = res.data
                })
        },
        differenceDate(start, end) {
            if (!start || !end) {
                return 0
            }
            return (((new Date(end.replace(/\./g, '/')).getTime() - new Date(start.replace(/\./g, '/')).getTime()) / 24 / 60 / 60 / 1000) + 1).toFixed(0)
        },
        cancelDate(openDate, day = 0) {
            return getFormatDate((new Date(openDate.replace(/\./g, '/')).getTime()) + (day * 24 * 60 * 60 * 1000), 'yyyy.MM.dd')
        },
        showRule(v) {
            this.ruleShow = true
            if (v === 1) {
                this.rule = this.activityRule
                this.ruleTitle = '自助健身卡规则及权益说明'
            } else {
                this.rule = this.FAQ
                this.ruleTitle = '常见问题'
            }

        },
        moreActions(v, index) {
            switch (index) {
                case 0:
                    this.goSellRecord(0)
                    break
                // case 1:
                //     this.jumpRecord()
                //     break
                case 1:
                    this.ruleShow = true
                    this.rule = this.activityRule
                    this.ruleTitle = '团课月卡规则及权益说明'
                    break
                case 2:
                    this.ruleShow = true
                    this.rule = this.FAQ
                    this.ruleTitle = '常见问题'
                    break
            }
        },
        jumpRecord() {
            this.$router.push({
                path: `/give-equity-card/record?userId=${this.userId}&token=${this.token}`,
            })
        },
        // 赠送卡
        share(userEquityCardId) {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/give-equity-card&userId=1&token=1&recordId=${userEquityCardId}`
                })
            } else {
                this.$router.push({
                    path: '/give-equity-card',
                    query: {recordId: userEquityCardId}
                })
            }
        },
    },
}
</script>
<style lang="less" scoped>

.coupon-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    .coupon-item-box{
        //margin-right: 10px;
        margin-bottom: 24px;
        //&:nth-child(4n){
        //    margin-right: 0;
        //}
    }
    .big-coupon-item {
        width: 298px;
        height: 128px;
        background: url("https://img.chaolu.com.cn/ACT/schedule-2023/coupon1.png") no-repeat center center;
        background-size: 100% 100%;
        color: #683E14;
        margin-left: 26px;
        display: flex;
        padding: 8px;
        box-sizing: border-box;
        align-items: center;
        &>div{
            width: 142px;
            h2{
                text-align: center;
                line-height: 52px;
                font-size: 52px;
                font-family: BebasNeue;
                span{
                    font-size: 36px;
                    margin-right: 4px;
                }
            }
            p{
                font-size: 20px;
                line-height: 20px;
                text-align: center;
                color: #683E14;
                font-weight: 400;
            }
        }
        h4{
            line-height: 28px;
            font-size: 26px;
            flex-grow: 1;
            text-align: center;
        }
        &.couponItemGray{
            background-image: url("https://img.chaolu.com.cn/ACT/schedule-2023/coupon1_gray.png");
            color: #fff;
            &>div{
                color: #242831;
                p{
                    color: #242831;
                }
            }
        }
    }
    .coupon-item{
        width: 148px;
        height: 162px;
        background: url("https://img.chaolu.com.cn/ACT/schedule-2023/coupon.png") no-repeat center center;
        background-size: 100% 100%;
        color: #683E14;
        margin-left: 10px;
        &.couponItemGray{
            background-image: url("https://img.chaolu.com.cn/ACT/schedule-2023/coupon_gray.png");
            color: #fff;
        }
        h2{
            padding-top: 30px;
            text-align: center;
            line-height: 64px;
            font-size: 64px;
            font-family: BebasNeue;
            span{
                font-size: 36px;
            }
        }
        p{
            font-size: 22px;
            line-height: 22px;
            font-weight: 600;
            text-align: center;
            margin-top: 26px;
        }
    }
}
.container {
    padding: 20px 32px 100px;
    background-color: #f5f5f5;
    min-height: 100vh;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
}

.content {
    .scroll-box {
        width: 686px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .cl-card {
            margin-right: 14px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        width: 100%;
        text-align: right;

        .more {
            font-weight: 600;
            font-size: 54px;
        }
    }
    .more-btn{
        width: 152px;
        height: 56px;
        border-radius: 4px;
        color: #242831;
        font-size: 24px;
        border: 1px solid #6C727A;
        box-sizing: border-box;
    }
    .btn {
        //margin: 26px 0 0;
        width: 152px;
        //height: 56px;
        background-color: #ED5C42;
        border-radius: 4px;
        color: white;
        margin-left: 20px;
        div {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            background: #3c454e;
        }
        &.btn1{
            div{
                height: 56px;
            }
        }
        p {
            width: 100%;
            padding: 6px 8px;
            box-sizing: border-box;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .user {
        .avator {
            width: 72px;
            border-radius: 50%;
        }
        .name {
            margin: 0 0 0 14px;
            p{
                width: 260px;
            }
            .status {
                margin: 8px 0 0;
            }
        }
    }
    .card {
        width: 686px;
        padding: 36px 32px;
        background-size: 100% 100%;
        margin: 24px auto 0;
        border-radius: 16px;
        color: #fff;
        height: 360px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            max-width: 8em;
            margin: -8px 0 0;
        }

        .limit {
            margin: 36px 0 0;
            width: 100%;

            p {
                color: #242831;
                font-weight: bold;
                padding: 2px 8px;
                font-size: 20px;
                border-radius: 4px;
                background: rgba(255,255,255,0.6);

            }


        }

        .status1 {
            background-color: rgba(0, 0, 0, 0.6);
            padding: 0 12px;
            height: 44px;
            line-height: 44px;
            border-radius: 8px;
        }
    }

    .equity-date {
        margin-top: 24px;
        color: #242831;
        font-size: 28px;
        font-weight: bold;
        span{

            font-size: 22px;
            color: #3C454E;
            font-weight: 400;
        }
    }

    .cl-card {
        width: 100%;
        padding: 40px 32px 40px;
        background: #ffffff;
        border-radius: 16px;
        margin: 24px 0 0;
        overflow: hidden;

        &.small {
            width: 686px;
        }

        .title {
            max-width: 470px;
        }

        .t2 {
            margin: 8px 0 0;
        }

        .p1 {
            margin: 12px 0 0;
            color: #6c727a;
        }

        .van-progress {
            margin: 40px 0 24px;
        }

        .status {
            margin: 40px 0 0;
        }

        .van-icon {
            color: #9aa1a9;
            font-weight: 600;
            margin: 0 0 0 6px;
        }
    }
}

/deep/ .van-popup {
    background-color: transparent;
}

/deep/ .popup-box {
    width: 654px;

    .title {
        text-align: center;
        margin: 0 0 28px;
        color: #242831;
    }

    .rule-box {
        width: 654px;
        max-height: 900px;
        background-color: #fff;
        padding: 60px 0;
        color: #6c727a;
        border-radius: 12px;
        box-sizing: border-box;

        .scroll {
            max-height: 800px;
            padding: 0 48px;
            box-sizing: border-box;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            white-space: pre-line;
            word-break: break-all;
        }
    }

    i {
        margin: 32px 0 0;
    }
}
.activate-btn{
    width: 144px;
    height: 64px;
    background: #FF6E00;
    border-radius: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
}

//最新卡
.item-box-title{
    margin-top: 40px;
    font-size: 28px;
    color: #242831;
    font-weight: bold;
}
.item-box{
    margin-top: 24px;
    //padding: 24px 32px;
    background-color: white;
    margin-bottom: 24px;
    border-radius: 16px;
    .item{
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        padding: 24px 32px;
        border-radius: 16px;
        img{
            width: 128px;
            height: 128px;
            border-radius: 6px;
        }
        &>div{
            flex-grow: 1;
            padding-left: 20px;
            .name{
                font-size: 24px;
                line-height: 28px;
                font-weight: bold;
                color: #242831;
            }
            .date1{
                line-height: 20px;
                font-size: 20px;
                margin-top: 20px;
                color: #3C454E;
            }
            .date{
                line-height: 28px;
                font-size: 20px;
                margin-top: 16px;
                color: #3C454E;
            }
        }
    }
    .item-box-btn{
        padding: 24px 32px;
        p{
            width: 144px;
            height: 56px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            background: #F5F5F5;
            color: #242831;
            font-size: 24px;
            margin-left: 20px;
            font-weight: bold;
            &.active{
                background: #FF6E00;
                color: #FFFFFF;
            }
        }
    }
}
</style>
